<template>
	<div class="my-5">
		<div class="d-flex justify-content-between">
			<b-input v-model="sentence.sentence" type="text" />
      <b-button
        variant="flat-danger"
        class="btn-icon ml-1"
        @click="$emit('remove')"
      >
        <feather-icon icon="Trash2Icon" />
      </b-button>
		</div>

    <div class="row">

      <div class="col col-6">
        <audio-recorder
          class="my-5"
          :audio-file="audioFile"
          :time-limit="sentence.duration"
          :audio-name="`${sentence._id}`"
          @setFile="setAudioFile"
        />
        <loading-button size="small"
          variant="warning"
          @click="uploadAudio"
          :disabled="audioFile == null || isUploading"
          :is-loading="isUploading"
        >
          <span>Check Result</span>
        </loading-button>
      </div>
      <div class="col col-6">
        <div v-if="result" class="my-2">
          <h4>X3 AI Result</h4>
          <i>score: {{total_score | float_rounded(2)}}</i>
          <div class="my-2">
            <word-component v-for="(word, index) in result" :key="index"
              :word="word"
            />
          </div>
        </div>
        <div v-if="false" class="my-2">
          <h4>SpeechAce Result</h4>
          <i>score: {{total_score | float_rounded(2)}}</i>
          <div class="my-2">
            <word-component v-for="(word, index) in result" :key="index"
              :word="word"
            />
          </div>
        </div>
      </div>
    </div>

	</div>
</template>
<script>

import api from '../service';

export default {
	components: {
		AudioRecorder: () => import('@core/components/audio-recorder/AudioRecorder.vue'),
		WordComponent: () => import('./WordComponent.vue')
	},
	data() {
		return {
			audioFile: null,
			result: null,
			total_score: null,
			isUploading: false
		}
	},
	props: {
		sentence: {
			type: Object,
			default: null
		}
	},
	methods: {
		setAudioFile(file) {
      this.audioFile = file;
		},
		uploadAudio() {
			this.isUploading = true;
			let data = new FormData();
			data.append("token", "gcxpHQmLeVwLWobE6apU1lgAg49YTMa0");
			data.append("audio-file", this.audioFile);
			data.append("text-refs", this.sentence.sentence);

			api.pronunciationZh(data).then((response) => {
				if(response.data.success) {
					this.result = response.data.result;
					this.sentence.result = response.data;
					this.total_score = response.data.total_score;
					this.isUploading = false;
				}
			}).catch((error) => {
				console.log(error)
			})
		}
	}
}
</script>
